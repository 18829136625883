/* All settings */

.dbg-anchor-point {
  background-color: red;
  color: red;
  width: 2px;
  height: 2px;
  font-size: 8px;
}

.dbg-anchor-point > p {
  margin-left: 1px;
}

/* --countdown- prefix for countdown */
:root {
  --countdown-x: 300px;
  --countdown-y: 200px;
  --countdown-width: min(400px, max(250px, 60vw));
  --countdown-animation-duration: 2.5s;
  --countdown-wh-ratio: 0.268;
  --countdown-text-width-h-ratio: 0.33;
  --countdown-box-opacity: 1;
  --countdown-label-y-center: 0.8;
  --countdown-label-x-center-px: calc(var(--countdown-top) + var(--countdown-height) * var(--countdown-label-y-center));
  --countdown-2nd-partition: 0.5;
  --countdown-3rd-partition: 0.75;
  --countdown-text-height-scale: 1;
  --countdown-label-font-size: 1.5;
  --countdown-label-font-size-px: calc(0.08 * var(--countdown-height) * var(--countdown-label-font-size));

  /* Beggining and end portion of the partition lines that seperate the columns */
  --countdown-top-partition: 0.15;
  --countdown-bot-partition: calc(1 - var(--countdown-top-partition));
  --countdown-height: calc(var(--countdown-width) * var(--countdown-wh-ratio));
  --countdown-top: calc(var(--countdown-y) - var(--countdown-height) / 2);
  --countdown-left: calc(var(--countdown-x) - var(--countdown-width) / 2);
  --countdown-text-height: calc(var(--countdown-text-height-scale) *0.4 * var(--countdown-height));
  --countdown-top-partition-px: calc(var(--countdown-top) + var(--countdown-height) * var(--countdown-top-partition));
  --countdown-partition-height-px: calc(2 * (var(--countdown-y) - var(--countdown-top-partition-px)));
  --countdown-bot-partition-px: calc(var(--countdown-top) + 2*var(--countdown-partition-height-px));

  /* These don't work too well, but they can be inlined */

  --countdown-text-width-px: calc(var(--countdown-text-width-h-ratio) * var(--countdown-text-height));

  /* define the horizontal partition elements */
  --countdown-1st-partition-px: calc(0.25 * var(--countdown-width) + var(--countdown-left));
  --countdown-2nd-partition-px: calc(0.5 * var(--countdown-width) + var(--countdown-left));
  --countdown-3rd-partition-px: calc(0.75 * var(--countdown-width) + var(--countdown-left));

  /* These are the x-centered values for the days/hours/min/secs column */
  --countdown-x-days: calc(0.125 * var(--countdown-width) + var(--countdown-left));
  --countdown-x-hours: calc(0.375 * var(--countdown-width) + var(--countdown-left));
  --countdown-x-mins: calc(0.625 * var(--countdown-width) + var(--countdown-left));
  --countdown-x-secs: calc(0.875 * var(--countdown-width) + var(--countdown-left));
  --countdown-box-shadow: 0 0 1px 2px rgb(255 255 255 / 35%);
}

/* This is a two-second animation. All animations are intended synchronized to the
same clock */
@keyframes box-expand {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    top: var(--countdown-y);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
    background-color: white;
    visibility: visible;
  }

  4% {
    width: 0;
    height: 0;
    top: var(--countdown-y);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
    background-color: white;
  }

  8% {
    width: 0;
    height: 0;
    top: var(--countdown-y);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
    background-color: white;
  }

  22% {
    width: 0;
    height: 0;
    top: var(--countdown-y);
    box-shadow: var(--countdown-box-shadow);
  }

  34% {
    width: calc(var(--countdown-width) * 0.988);
    box-shadow: var(--countdown-box-shadow);
    background-color: white;
  }

  40% {
    width: var(--countdown-width);
    height: 0;
    top: var(--countdown-y);
    background-color: white;
  }

  50% {
    width: var(--countdown-width);
    height: 0;
    top: var(--countdown-y);
    background-color: white;
  }

  63% {
    width: var(--countdown-width);
    height: 0;
    opacity: var(--countdown-box-opacity);
    top: var(--countdown-y);
    background-color: rgb(255 255 255 / 0%);
  }

  65% {
    width: var(--countdown-width);
    background-color: rgb(255 255 255 / 0%);
  }

  68% {
    width: var(--countdown-width);
    height: var(--countdown-height);
    top: calc(var(--countdown-y) - var(--countdown-height) * 0.5);
  }

  83% {
    width: var(--countdown-width);
    height: var(--countdown-height);
    opacity: calc(var(--countdown-box-opacity) * 0.5);
  }

  100% {
    width: var(--countdown-width);
    height: var(--countdown-height);
    opacity: 1;
    border-color: rgb(0 0 0 / 0%);
    top: calc(var(--countdown-y) - var(--countdown-height) * 0.5);
    visibility: visible;

    /* text-shadow */

    /* box-shadow */
  }
}

@keyframes verticle-lines {
  0% {
    opacity: 0;
    visibility: visible;
  }

  34% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  63% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  68% {
    opacity: 1;
  }

  71% {
    opacity: 1;
  }

  83% {
    opacity: 0.4;
  }

  94% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.4;
    visibility: visible;
  }
}

@keyframes label-animation {
  0% {
    margin-top: 4px;
    opacity: 0;
    visibility: visible;
  }

  34% {
    margin-top: 4px;
    opacity: 0;
  }

  40% {
    margin-top: 4px;
    opacity: 0;
  }

  63% {
    margin-top: 4px;
    opacity: 0;
  }

  65% {
    margin-top: 4px;
    opacity: 0;
  }

  94% {
    margin-top: 0;
    opacity: 0.7;
  }

  100% {
    margin-top: 0;
    opacity: 0.7;
    visibility: visible;
    text-shadow:
      -1px -1px 6px #000,
      0 -1px 6px #000,
      1px -1px 6px #000,
      1px 0 6px #000,
      1px 1px 6px #000,
      0 1px 6px #000,
      -1px 1px 6px #000,
      -1px 0 6px #000;
  }
}

@keyframes margin-left-animation {
  0% {
    margin-left: -20px;
    visibility: visible;
  }

  34% {
    margin-left: -20px;
  }

  40% {
    margin-left: -20px;
  }

  63% {
    margin-left: -20px;
  }

  65% {
    margin-left: 0;
  }

  94% {
    margin-left: 0;
  }

  100% {
    margin-left: 0;
    visibility: visible;
  }
}

@keyframes number-opacity {
  0% {
    opacity: 0;
    visibility: visible;
  }

  34% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  63% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  68% {
    opacity: 1;
  }

  71% {
    opacity: 1;
  }

  83% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    visibility: visible;
    text-shadow:
      -1px -1px 8px rgb(0 0 0 / 25%),
      0 -1px 8px rgb(0 0 0 / 25%),
      1px -1px 8px rgb(0 0 0 / 25%),
      1px 0 8px rgb(0 0 0 / 25%),
      1px 1px 8px rgb(0 0 0 / 25%),
      0 1px 8px rgb(0 0 0 / 25%),
      -1px 1px 8px rgb(0 0 0 / 25%),
      -1px 0 8px rgb(0 0 0 / 25%);
  }
}

@keyframes content-pane-opaque-anim {
  0% {
    opacity: 0;
    visibility: visible;
  }

  34% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  63% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  68% {
    opacity: 0.5;
  }

  71% {
    opacity: 1;
  }

  83% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.countdown-number {
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: calc(var(--countdown-y));
  color: white;
  font-family: "Roboto Mono", "B612 Mono", "Courier New", Courier, monospace !important;
  font-size: var(--countdown-text-height) !important;
  line-height: 0 !important;
  animation-name: number-opacity;
  animation-play-state: paused;
  animation-duration: var(--countdown-animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.countdown-label {
  pointer-events: none;
  position: absolute;
  top: var(--countdown-label-x-center-px);
  color: white;
  font-family: Roboto, sans-serif;
  font-size: var(--countdown-label-font-size-px);
  line-height: 0 !important;
  animation-name: label-animation;
  animation-play-state: paused;
  animation-duration: 1.7s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.countdown-vert-sep {
  border-radius: 1px;
  margin: 0;
  padding: 0;
  color: white;
  position: absolute;
  top: calc(var(--countdown-y) - var(--countdown-partition-height-px) / 2);
  left: var(--countdown-left);
  height: var(--countdown-partition-height-px);
  background-color: rgb(0 0 0 / 0%);
  border-left: 1px solid rgb(255 255 255 / 50%);
  width: 0 !important;
  animation-name: verticle-lines;
  animation-play-state: paused;
  animation-duration: var(--countdown-animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

#countdown-border-box {
  margin: 0;
  padding: 0;
  border-radius: 1px;
  color: white;
  position: absolute;
  border: solid #fff 1px;
  top: var(--countdown-y);
  left: var(--countdown-left);
  width: var(--countdown-width);
  height: var(--countdown-height);
  animation-fill-mode: both;
  animation-name: box-expand, margin-left-animation;
  animation-play-state: paused;
  animation-duration: var(--countdown-animation-duration);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

#countdown-content-box {
  margin: 0;
  padding: 0;
  animation-name: content-pane-opaque-anim;
  animation-play-state: paused;
  animation-duration: var(--countdown-animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

#countdown-days {
  left: calc(var(--countdown-x-days) - 2 * var(--countdown-text-width-px));
}

#countdown-days-label {
  animation-delay: 0.05s;
  left: calc(var(--countdown-x-days) - 2 * var(--countdown-text-width-px));
}

#countdown-hours {
  left: calc(var(--countdown-x-hours) - 2 * var(--countdown-text-width-px));
}

#countdown-hours-label {
  animation-delay: 0.1s;
  left: calc(var(--countdown-x-hours) - 2 * var(--countdown-text-width-px));
}

#countdown-mins {
  left: calc(var(--countdown-x-mins) - 2 * var(--countdown-text-width-px));
}

#countdown-mins-label {
  animation-delay: 0.15s;
  left: calc(var(--countdown-x-mins) - 2 * var(--countdown-text-width-px));
}

#countdown-secs {
  left: calc(var(--countdown-x-secs) - 2 * var(--countdown-text-width-px));
}

#countdown-secs-label {
  animation-delay: 0.2s;
  left: calc(var(--countdown-x-secs) - 2 * var(--countdown-text-width-px));
}

#countdown-vert-sep-1 {
  left: calc(var(--countdown-1st-partition-px) + 1px);
}

#countdown-vert-sep-2 {
  left: calc(var(--countdown-2nd-partition-px) + 1px);
}

#countdown-vert-sep-3 {
  left: calc(var(--countdown-3rd-partition-px) + 1px);
}

.countdown-target-anchor {
  position: absolute;
  display: inline;
  left: 50%;
  padding: 0;
  margin-top: calc(var(--countdown-height) / 2);
  margin-bottom: calc(var(--countdown-height) / 2);
}
