/* todo fill in */

#section-donation {
  background-color: rgb(0 0 0 / 70%);
  padding-top: 2em;
  padding-bottom: 6em;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  backdrop-filter: blur(15px);
}

/* if mobile phone */
@media screen and (max-width: 600px) {
  #section-donation {
    backdrop-filter: blur(0);
    background-color: rgb(0 0 0 / 85%);
  }
}

#btn-donate-now,
#btn-donate-now-cash-app {
  background-color: rgb(127 3 3);
  font-size: 14pt;
  width: 100%;
  padding: 0;
  margin-top: 2em;
  box-sizing: border-box;
  text-transform: capitalize;
}

#div-raised p {
  font-size: 14pt;
  color: #f2f2f2;
  margin-top: 1em;
  text-align: center !important;
}

#p-help-fund-film {
  font-size: 14pt;
  font-weight: bold;
  color: #f2f2f2;
  text-align: center !important;
  justify-content: center;
  margin-bottom: 0;
}

.section-donation-flexbox {
  display: flex;
  gap: 1em 5em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

#section-donation * {
  color: white;
}

#section-donation a {
  text-transform: uppercase;
}

#section-donation h1,
h2 {
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-weight: bold;
  text-align: center;
}

#section-donation p {
  text-align: left;
}

#section-donation header {
  text-align: center !important;
  margin-top: 2em;
}

#section-donation img {
  filter: drop-shadow(0 0 0.2em #000) drop-shadow(0 0 0.1em #000);
}

#section-donation li {
  /* Shift tex up a bit */
  padding-top: 5px;
  text-align: initial;
  margin-left: 3em;
  text-indent: -3em;
}

#section-donation ul {
  list-style-type: none;
}

#section-donation h2 {
  font-size: xx-large;
  font-family: "Roboto Condensed", Arial, sans-serif;
  color: black !important;

  /* make bold */
  font-weight: bold;
}

#section-donation div.row {
  font-size: large;
  font-family: Oswald, Arial, sans-serif;
  color: black !important;

  /* make bold */
  font-weight: bold;
}

#section-donation-content {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 630px) {
  .section-donation-flexbox {
    flex-direction: column;
  }
}

.button-glow {
  width: 220px;
  height: 50px;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  opacity: 1;
}

.button-glow::before {
  content: "";
  background:
    linear-gradient(
      45deg,
      rgb(255 0 0 / 100%),
      rgb(255 255 255 / 100%),
      rgb(255 255 255 / 100%),
      rgb(0 0 0 / 0%),
      rgb(255 0 0 / 100%),
      rgb(255 0 0 / 100%),
      rgb(255 255 255 / 100%),
      rgb(255 255 255 / 100%),
      rgb(255 0 0 / 100%)
    );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.button-glow::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(127 3 3);
  left: 0;
  top: 0;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: blur(5px);
  transition: background-color 0.3s ease-in-out;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

#btn-donate-now:hover::after,
#btn-donate-now-cash-app:hover {
  background-color: black !important;
  color: white;
  border: 2px solid white;
  font-weight: 100;
}

#btn-donate-now:hover {
  font-weight: 100;
}

#donation-testimonies {
  margin: 0.5em 0 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

#donation-testimonies.show {
  margin: 1em 0 0;
  opacity: 1;
}

#donation-testimonies > p {
  /* center text */
  text-align: center;
  font-size: small;
}
