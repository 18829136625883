/* todo fill in */

#section-about {
  background-color: black;
  margin-bottom: 2em;
}

#section-about header {
  text-align: center !important;
  margin-top: 2em;
}

#section-about * {
  color: white;
}

#section-about h2 {
  font-size: xx-large;
  font-family: "Roboto Condensed", Arial, sans-serif;
  color: rgb(237 57 57) !important;
}

#section-about h2,
h1 {
  text-align: center;
}

#section-about-icons {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 0.75fr));
  grid-gap: 10px;
  grid-template-rows: auto;
  gap: 2em 4em;
  align-items: flex-start;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: black;
  justify-content: space-around;
  align-content: center;
}

@media (max-width: 1040px) {
  #section-about-icons {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
}

#section-about-icons div.flexitem {
  width: 100%;
  flex-grow: 4;
}

#section-about-cta-div2 p {
  width: calc(100% - 10em);
  font-size: large;
  justify-content: center;
  text-align: center;
  user-select: none;
}

#section-about-icons div.flexitem p {
  font-size: large;
  color: white !important;
  line-height: 1.3em;
  margin-top: 0.5em;
  text-align: center !important;
}

#section-about-icons div.flexitem a:hover {
  color: red !important;
}

#section-about img {
  user-select: none;
  border-radius: 50%;
}

#section-about-cta-div1 img {
  height: 100%;
  object-fit: cover;
}

#section-about-cta-div2 img {
  margin-top: 2em;
  margin-bottom: 1em;
}

#section-about-icons div.flexitem img {
  width: 100%;
}

#section-about-cta {
  display: flex;
  gap: 1em 5em;
  flex-wrap: wrap;

  /* stylelint-disable-next-line */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#section-about-cta div {
  width: 50%;
}

#section-about-cta-div2 {
  background-color: #e8f1f5;

  /* center everything */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px 20px 40px;
}

@media (max-width: 800px) {
  #section-about-icons {
    flex-direction: column;
  }

  #section-about-cta {
    flex-direction: column;
  }

  #section-about-cta div {
    width: 100%;
  }
}

/* mobile */
@media (max-width: 600px) {
  #section-about-icons div.flexitem p {
    font-size: small;
  }
}
