/* stylelint-disable color-function-notation,declaration-block-no-redundant-longhand-properties */

#signup {
  position: block;
  color: black;

  /* center the signup form */
  margin: 5em auto auto;
  width: 60%;

  /* blur background */
  backdrop-filter: blur(10px);

  /* Center the element */
  max-width: 90vw;
  background-color: rgba(0, 0, 0, 50%);
  border-radius: 25px;
  border: 3px solid rgb(128, 3, 3);
  opacity: 0;
  pointer-events: none;
  padding: 2em;
  transition: all 0.25s ease-in-out;
  text-shadow:
    1px 1px 2px #000,
    1px 1px 2px #000,
    1px 1px 5px #000,
    1px 1px 5px #000,
    1px 1px 10px #000,
    1px 1px 10px #000,
    1px 1px 2px #000,
    1px 1px 2px #000,
    1px 1px 5px #000,
    1px 1px 5px #000,
    1px 1px 10px #000,
    1px 1px 10px #000;
  z-index: 4;
}

@media screen and (max-width: 630px) {
  #signup {
    width: 90%;
    background-color: rgba(0, 0, 0, 80%);
    backdrop-filter: none;
  }
}

#signup.active {
  opacity: 1;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
}

/* All elements contained by #signup */
#signup > * {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  color: white;
}

/* signup > input */
#signup input {
  color: white;
  background-color: rgba(0, 0, 0, 10%);
  font-family: "Roboto Condensed", sans-serif !important;
  text-transform: uppercase;
}

#signup-email {
  border: 4px solid rgb(128, 3, 3) !important;
  background-color: rgba(0, 0, 0, 70%) !important;
  font-family: "Roboto Condensed", sans-serif !important;
  margin-top: 2em;
}

#signup-privacy-statement {
  font-size: x-small;
  color: #bbb;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
}

#btn-signup {
  /* smaller size */
  font-size: smaller;
  margin-top: 10px;
  border: 2px solid rgb(128, 3, 3);
  color: rgb(128, 3, 3);
  box-shadow: 0 0 1px rgba(128, 3, 3, 100%);
}

#btn-signup:hover {
  background-color: white;
  color: black;
}

#signup-close-btn {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #999;
  cursor: pointer;
  left: 0%;
  top: 0%;

  /* use fa fa-times as the icon */
  font-family: FontAwesome; /* stylelint-disable-line */
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5em;
  color: #999;
  margin: 0;
  padding: 0;
  z-index: 5;
}
