#iframe-movie {
  aspect-ratio: var(--aspect-ratio);
  width: var(--video-width-size);
  position: fixed;
  top: calc(100vh - var(--video-width-size));
  left: calc((100% - var(--video-width-size))/2);
  max-height: 80vh;
  max-width: 100vw;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

#iframe-movie.active {
  opacity: 1;
  pointer-events: all;
}

#iframe-movie-close-button {
  position: fixed;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #999;
  top: calc(100vh - var(--video-width-size) - 0.5em);
  left: calc((100% - var(--video-width-size))/2 - 0.5em);
  cursor: pointer;

  /* use fa fa-times as the icon */
  font-family: FontAwesome; /* stylelint-disable-line */
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5em;
  color: #999;
  margin: 0;
  padding: 0;
  z-index: 4;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  user-select: none;
}

#iframe-movie-close-button.active {
  display: block;
  opacity: 1;
  visibility: visible;
  user-select: all;
}
